import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import RefForm from '../components/RefForm';

const RegistroRef = ({ data }) => (
  <Layout>
    <section>
      <div className="content">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <RefForm 
            services={data.allServices} 
            products={data.allProducts}
            countries={data.allCountries} />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default RegistroRef

export const REFFormQuery = graphql`
  {
    allServices {
      edges {
        node {
          name
        }
      }
    }
    allProducts {
      edges {
        node {
          name
        }
      }
    }
    allCountries {
      edges {
        node {
          id
          pais
          codigo
          provinces
        }
      }
    }    
  }
`